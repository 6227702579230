import styled from "styled-components"

const LayoutContainer = styled.div`
  padding: 5vw;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: "Lacquer", sans-serif;
  main {
    min-height: 50vh;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-size: 1rem;
    font-family: "Lacquer", sans-serif;
  }
  a {
    font-family: "Lacquer", sans-serif;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  footer {
    display: none;
  }
`

export { LayoutContainer }
